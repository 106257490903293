<template>
  <div class="pixi-interaction">
    <div class="pixi-interaction__stage" ref="stage"></div>
    <div class="pixi-interaction__buttons">
      <Button class="button--small" @click.native="onDownloadClick"
        >DOWNLOAD</Button
      >
    </div>
  </div>
</template>

<script>
import * as PIXI from "pixi.js";
import gsap from "gsap";
import DownloadHelperMixin from "../../../mixins/DownloadHelperMixin";
import Button from "../../../components/form/Button";

export default {
  name: "PixiInteraction",
  mixins: [DownloadHelperMixin],
  components: { Button },
  data() {
    return {
      currentPos: { x: 0, y: 0 },
      targetPos: { x: 0, y: 0 },
      app: undefined,
      kaleidoscope: undefined,
      image: undefined,
      rect: undefined,
      timer: 0,
      maskImages: [],
      numberOfSectors: 8
    };
  },
  methods: {
    onDownloadClick() {
      this.createPngTextureFromPixi(
        this.app,
        "Kaleidoscope.png",
        this.kaleidoscope
      );
    },
    pixiResize() {
      const rect = this.$refs.stage.getBoundingClientRect();
      this.rect = rect;
      let w = rect.width;
      let h = rect.height;

      // resize the renderer
      this.app.renderer.view.style.width = w + "px";
      this.app.renderer.view.style.height = h + "px";
      this.app.renderer.resize(w, h);

      // reposition kaleidoscope container so it always stays in the middle
      this.kaleidoscope.position.set(w / 2, h / 2);
    },
    initScene() {
      //const rect = this.$refs.stage.getBoundingClientRect();

      //Create a Pixi Application
      let app = new PIXI.Application({
        width: 700,
        height: 500,
        antialias: true,
        backgroundColor: 0x000000
      });

      app.stage.interactive = true;
      app.stage.on("pointermove", this.onMouseMove);

      this.app = app;

      this.image = PIXI.Texture.from("/assets/img/Caleidoscope-100.jpg");

      this.$refs.stage.appendChild(app.view);

      this.createKaleidoscope();
      this.animate();

      this.pixiResize();
    },
    createKaleidoscope() {
      let radius = 4000;
      let colors = [
        "0xC477AA",
        "0xD782BA",
        "0xDA8DC0",
        "0xDE98C6",
        "0xE1A4CC",
        "0xE5AFD3",
        "0xE9BAD9",
        "0xECC6DF",
        "0xF0D1E5",
        "0xf4DCEC",
        "0xF7E8F2",
        "0xFBF3F8"
      ];
      // calculate the length of an arc
      let arc = (2 * Math.PI) / this.numberOfSectors;

      const w = this.rect.width;
      const h = this.rect.height;

      this.kaleidoscope = new PIXI.Container();
      this.kaleidoscope.position.set(w / 2, h / 2);

      for (let i = 0; i < this.numberOfSectors; i++) {
        // create the kaleidoscope "slices"
        let sector = new PIXI.Graphics();
        sector.beginFill(colors[i]);
        sector.moveTo(w / 2, h / 2);
        sector.arc(w / 2, h / 2, radius, -arc / 2, arc / 2);
        sector.endFill();

        let maskImage = new PIXI.TilingSprite(this.image, radius, radius);

        this.maskImages.push(maskImage);
        maskImage.mask = sector;

        let container = new PIXI.Container();
        container.addChild(maskImage);
        container.addChild(sector);
        container.pivot.x = w / 2;
        container.pivot.y = h / 2;
        container.rotation = arc * i;
        container.scale.x = i % 2 ? 1 : -1;

        this.kaleidoscope.addChild(container);
      }
      this.app.stage.addChild(this.kaleidoscope);
    },

    animate() {
      this.app.ticker.add(this.onAppTicker);
    },
    onAppTicker() {
      const velocity = 0.05;

      let delta = {
        x: this.targetPos.x - this.currentPos.x,
        y: this.targetPos.y - this.currentPos.y
      };

      this.targetPos = {
        x: this.targetPos.x - delta.x * velocity,
        y: this.targetPos.y - delta.y * velocity
      };

      this.timer += 0.3;

      for (let i = 0; i < this.numberOfSectors; i++) {
        this.maskImages[i].tilePosition.y = this.targetPos.y + this.timer;
        this.maskImages[i].tilePosition.x = this.targetPos.x + this.timer;
      }
    },
    onMouseMove(e) {
      this.currentPos.x = e.data.global.x;
      this.currentPos.y = e.data.global.y;
    }
  },
  watch: {
    stageWidth() {
      this.pixiResize();
    }
  },
  computed: {
    stageWidth() {
      return this.$screen.width;
    }
  },
  beforeDestroy() {
    this.app.ticker.remove(this.onAppTicker);
    this.app.stage.off("pointermove", this.onMouseMove);
    this.app.destroy();
    this.app = undefined;
  },

  mounted() {
    const rect = this.$refs.stage.getBoundingClientRect();
    this.rect = rect;
    this.initScene();

    gsap.from(this.$el, {
      ease: "Expo.easeOut",
      duration: 2,
      scale: 1.2,
      alpha: 0
    });
  }
};
</script>
